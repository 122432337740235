import React from "react";
import { motion } from "framer-motion";
import { LeftTitle } from "../components/LeftTitle";
import { LeftTitleVertical } from "../components/LeftTitleVertical";
import { RightTitle } from "../components/RightTitle";
import { RightTitleVertical } from "../components/RightTitleVertical";
import { Breadcrumb } from "../components/Breadcrumb";
import { GeoJsonMap } from "../components/Mapa";
import { Fallback } from "../components/fallback";
import { url, urlRestAPI } from "../queries/URL";
import { getDisponibilidad, getModelos, getBusinessIdentity } from "../queries/Queries";
import "leaflet/dist/leaflet.css";
import "../stylesheets/dist/disponibilidad.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Drawer } from "../components/Drawer";
import { Title } from "../components/Title";
import { Loading } from "../components/Loading.js";
import { CircleSpinner } from "react-spinners-kit";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";
import { GeoJsonMapBack } from "../components/MapaBack";

const tipos = ["No Disponible", "No filtrado", "Disponible", "Separado", "Vendido"];



/**
 * Regresa el componente de la pantalla DISPONIBILIDAD
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {object}   geo     Datos del geojson
 * @param  {object}   disponibilidad  Datos de la disponibilidad
 * @return {Component}        Componente de la pantalla DISPONIBILIDAD
 */
var n = 0;
var x;
export class DisponibilidadCompleta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      data3: undefined,
      geo: undefined,
      clicked: false,
      disponibilidad: undefined,
      refresh: undefined,
      torre:{}
    };
    this._onButtonClick = this._onButtonClick.bind(this);
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();

  }

  /**
   * Hace la petición y guarda la respuesta en data.
   * Lee el archivo geojson y lo guarda en geo
   * Hace la petición de la disponibilidad y la guarda en disponibilidad
   */
  componentDidMount() {
    console.log('yes Update');

    localStorage.setItem("mapa", "false")
    //var data3 = getBusinessIdentity(this.props.match.params.desarrollo);
    console.log('params.desarrollo',this.props.match.params.desarrollo);
    const requestOptions2 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo: this.props.match.params.desarrollo })
    };
    fetch(urlRestAPI + "/menu", requestOptions2)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          //console.log(response.json())
          var data = response.json();
          console.log(data)
          return data;
        } else {
          return false;
        }
      })
      .then((recurso) => {
        if (recurso === false) {
          this.setState({
            refresh: recurso,
          });

        } else {
          var data = recurso;
          this.setState({
            data3: data.desarrollos[0],
          });
        }
      });
    /* data3.then((res) => {
       //console.log(res.data.data.desarrollos[0].identidad.Background.url)
       this.setState({
         data3: res.data.data.desarrollos[0],
       });
     })*/

    /*var data2 = getModelos(this.props.match.params.desarrollo);
    data2.then((res) => {
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo: this.props.match.params.desarrollo })
    };
    fetch(urlRestAPI + "/modelos", requestOptions)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          //console.log(response.json())
          var data = response.json();
          console.log(data)
          return data;
        } else {
          return false;
        }
      })
      .then((recurso) => {
        if (recurso === false) {
          this.setState({
            refresh: recurso,
          });

        } else {
          var data = recurso;
          this.setState({
            data2: data.desarrollos[0],
          });
        }
      });

  }



  consultaGis(id) {

    console.log('consultaGis()');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo: this.props.match.params.desarrollo, torre: id })
    };
    fetch(urlRestAPI + "/gis", requestOptions)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          //console.log(response.json())
          var data = response.json();
          return data;
        } else {
          return false;
        }
      })
      .then((recurso) => {
        console.log('recurso (data)', recurso)
        if (recurso === false) {
          this.setState({
            refresh: recurso,
          });

        } else {
          var data = recurso;
          var geoJSONString;
          var rawFile = new XMLHttpRequest();
          //var array = res.data.data.desarrollos[0].Torres;
          var array = data.desarrollos[0].Torres;
          rawFile.open(
            "GET",
            url + data.desarrollos[0].Torres[0].Geojson.url,
            false
          );
          rawFile.onreadystatechange = function () {
            try {
              if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                  var allText = rawFile.responseText;
                  geoJSONString = allText;
                }
              }
            } catch (error) {
              geoJSONString = {};
            }
          };
          rawFile.send(null);

          // Información de la disponibilidad
          var dataSembrado;
          rawFile.open(
            "GET",
            data.desarrollos[0].Torres[0].Disponibilidad,
            false
          );
          rawFile.onreadystatechange = function () {
            if (rawFile.readyState === 4) {
              if (rawFile.status === 200 || rawFile.status === 0) {
                var allText = rawFile.responseText;
                dataSembrado = allText;
              }
            }
          };
          rawFile.send(null);

          console.log('geoJSONString');
          //console.log('geoJSONString',geoJSONString);
          //console.log('dataSembrado');
          //console.log('dataSembrado',dataSembrado);
          // Guarda toda la información



      console.log('url torre',`${url}/torres/${id}`);
      fetch(`${url}/torres/${id}`)
      .then(dataFetch=>dataFetch.json())
      .then(dataTorre=>{
        console.log('torre dataTorre',dataTorre)
          this.setState({
            data: data,
            geo: geoJSONString,
            disponibilidad: JSON.parse(dataSembrado),
            showComponent: false,
            torre:dataTorre
        });
      })


          // Carga la fuente ya que la pantalla no tiene menú
          var name = data.desarrollos[0].identidad.Font.name.split(".")[0];
          var notoSansRegular = new FontFace(
            name,
            "url(/" + data.desarrollos[0].identidad.Font.name + ")",
            {
              style: "normal",
              weight: "400",
              display: "block",
            }
          );

          document.fonts.add(notoSansRegular);

          notoSansRegular.load();
        }
      });
    /*var data = getDisponibilidad(
      this.props.match.params.desarrollo,
      id,
     // localStorage.getItem("prototipoID")
    );*/


    // res.data.data.desarrollos[0]
    /*data.then((res) => {
     // Información del GeoJson
      
     var geoJSONString;
     var rawFile = new XMLHttpRequest();
     //var array = res.data.data.desarrollos[0].Torres;
     var array = res.data.data.desarrollos[0].Torres;
     rawFile.open(
       "GET",
       url + res.data.data.desarrollos[0].Torres[0].Geojson.url,
       false
     );
     rawFile.onreadystatechange = function () {
       try {
         if (rawFile.readyState === 4) {
           if (rawFile.status === 200 || rawFile.status === 0) {
             var allText = rawFile.responseText;
             geoJSONString = allText;
           }
         }
       } catch (error) {
         geoJSONString = {};
       }
     };
     rawFile.send(null);
 
     // Información de la disponibilidad
     var dataSembrado;
     rawFile.open(
       "GET",
       res.data.data.desarrollos[0].Torres[0].Disponibilidad,
       false
     );
     rawFile.onreadystatechange = function () {
       if (rawFile.readyState === 4) {
         if (rawFile.status === 200 || rawFile.status === 0) {
           var allText = rawFile.responseText;
           dataSembrado = allText;
         }
       }
     };
     rawFile.send(null);
 
     // Guarda toda la información
     this.setState({
       data: res.data.data,
       geo: geoJSONString,
       disponibilidad: JSON.parse(dataSembrado),
       showComponent:false
     });
 
     // Carga la fuente ya que la pantalla no tiene menú
     var name = res.data.data.desarrollos[0].identidad.Font.name.split(".")[0];
     var notoSansRegular = new FontFace(
       name,
       "url(/" + res.data.data.desarrollos[0].identidad.Font.name + ")",
       {
         style: "normal",
         weight: "400",
         display: "block",
       }
     );
 
     document.fonts.add(notoSansRegular);
 
     notoSansRegular.load();
     
   });*/
    //this.vistaGis();

  }
  _onButtonClick() {
    this.setState({
      showComponent: true,
    });
  }

  loading(id) {
    //this.consultaGis(item.id),localStorage.setItem("id",item.id),localStorage.setItem("torreID",item.id),localStorage.setItem("disponibilidad",true),localStorage.setItem("flag",true),localStorage.setItem("regreso",false),localStorage.setItem("carga",false)
    console.log('clic')
    //return <Loading />; 

    /* this.setState({
       loading: true,
     });*/
    if (localStorage.getItem("mapa") !== "true") {
      console.log("entro 1")

      //this.consultaGis(id)
      this.setState({
        showComponent: true,
      });
      let miPrimeraPromise = new Promise((resolve, reject) => {
        // Llamamos a resolve(...) cuando lo que estabamos haciendo finaliza con éxito, y reject(...) cuando falla.
        // En este ejemplo, usamos setTimeout(...) para simular código asíncrono.
        // En la vida real, probablemente uses algo como XHR o una API HTML5.
        setTimeout(function () {

          resolve("¡Éxito!"); // ¡Todo salió bien!
        }, 1000);
      });

      miPrimeraPromise.then((successMessage) => {
        // succesMessage es lo que sea que pasamos en la función resolve(...) de arriba.
        // No tiene por qué ser un string, pero si solo es un mensaje de éxito, probablemente lo sea.
        console.log("¡Sí! " + successMessage);
        console.log("torreID", id);
        this.consultaGis(id)

          localStorage.setItem("id", id)

          localStorage.setItem("torreID", id)
           localStorage.setItem("disponibilidad", true)

          localStorage.setItem("flag", true)
           localStorage.setItem("regreso", false)

          localStorage.setItem("carga", false)
          

      });

    } else {
      console.log("entro 2")
      localStorage.removeItem("prototipoID");
      localStorage.removeItem("torreName");
      //localStorage.removeItem("torreID");
      localStorage.removeItem("prototipoName");
      localStorage.removeItem("id")
      this.setState({
        showComponent: true,
        data: undefined,
        geo: undefined,
        disponibilidad: undefined,
      });
      let miPrimeraPromise = new Promise((resolve, reject) => {
        // Llamamos a resolve(...) cuando lo que estabamos haciendo finaliza con éxito, y reject(...) cuando falla.
        // En este ejemplo, usamos setTimeout(...) para simular código asíncrono.
        // En la vida real, probablemente uses algo como XHR o una API HTML5.
        setTimeout(function () {

          resolve("¡Éxito!"); // ¡Todo salió bien!
        }, 1000);
      });

      miPrimeraPromise.then((successMessage) => {
        // succesMessage es lo que sea que pasamos en la función resolve(...) de arriba.
        // No tiene por qué ser un string, pero si solo es un mensaje de éxito, probablemente lo sea.
        console.log("¡Sí! " + successMessage);

        this.consultaGis(id)

          localStorage.setItem("id", id)

          localStorage.setItem("torreID", id)
           localStorage.setItem("disponibilidad", true)

          localStorage.setItem("flag", true)
           localStorage.setItem("regreso", false)

          localStorage.setItem("carga", false)

      });

    }
  }

  changeScreen() {
    console.log('changeScreen');
    //let history = useHistory();
    //var initial=this.state.initial;
    /* console.log('stopped')
     if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
     clearInterval(x); 
     var n=0;
     setTimeout(() => {
       x = setInterval(function(){
         console.log(n);
         if (n==30) {
           location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
         }
         n++;
       },1000);
     }, 1000);
   }
 
     //clearTimeout( initial )
     //this.invocation(initial)
    
   //if(n==8){ clearInterval(x); }*/
    this.method.changeScreen();

  }

  render() {
    let loading = null;
    let datos = this.state.data2;
    let refresh = this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if (this.state.data2 == undefined) {
      localStorage.setItem("regreso", "false")
      return <Loading />

    } else {
      if (this.state.data == undefined && this.state.data2.Torres.length != 1 || localStorage.getItem("regreso") === "true" && this.state.showComponent !== true) {
        let componente = null;

        // let data =this.state.data2;
        if (datos == undefined) {
          return <Loading />
        } else {
          componente = datos.Torres;
          console.log('componente',componente);
          localStorage.removeItem("prototipoID");
          localStorage.removeItem("torreName");
          //localStorage.removeItem("torreID");
          localStorage.removeItem("prototipoName");
          localStorage.removeItem("id")
          // localStorage.removeItem("mapa")
        }
        return (
          <div onClick={this.changeScreen}>
            {!this.state.showComponent ? <div><Drawer data={this.state.data3} />
              <Title
                title={"Disponibilidad"}
              />
              <ButtonWithDropDown /></div> : ''}

            {/*componente*/}

            {!this.state.showComponent ? <div className="cards-list">
              {/* <OpcionPrivada  data={componente}/>*/}
              {componente.map((item, index) => {
                let path = item.Imagen?.url;
                return (
                  <div key={index + "card"} className="cardPrivada 1"
                    onClick={() => { this.loading(item.id) }}
                  //onClick={()=>{this.consultaGis(item.id),this.loading(item.id)}}
                  >

                    <div className="card_image" > <img src={url + path} /> </div>
                    <div className="card_title 1" style={{ border: "solid 2px " + localStorage.getItem("color") }}>
                      <svg
                        width="60"
                        height="5"
                        viewBox="0 0 86 5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={localStorage.getItem("color")}
                        className="barra"
                      >
                        <rect width="60" height="5" />
                      </svg>
                      <p className="tituloDesarrollo 1" style={{ color: localStorage.getItem("color") }}>{item.Nombre}</p>
                    </div>
                  </div>
                );
              })}
            </div> : ''}

            {(this.state.showComponent === true) ?
              <Loading /> :
              null
            }
          </div>
        );
      }
      /*if (this.state.loading===true) {
        console.log('entro')
        return (
          <div>
          <div id="loading">
            <div>
            <CircleSpinner size={150} color="#C07D00" loading={true} />;
               
            </div>
      
          </div>
          <div className="textoLoading">
          <p className="cargando">Cargando información...</p>
      </div>
      </div>
        );
      }*/
      else if (this.state.data != undefined && this.state.data2.Torres.length != 1) {
        let selector = <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />;
        let load = <Loading />;

        try {


          var data = this.state.data.desarrollos[0].Torres[0];
          const prueba = [];
          if (this.state.data.desarrollos[0].Torres[0].NoDisponible) {
            prueba.push("No Disponible");
          }
          if (this.state.data.desarrollos[0].Torres[0].Disponible) {
            prueba.push("Disponible");
          }
          if (this.state.data.desarrollos[0].Torres[0].NoFiltrado) {
            prueba.push("No Filtrado");
          }
          if (this.state.data.desarrollos[0].Torres[0].Separado) {
            prueba.push("Separado");
          }
          if (this.state.data.desarrollos[0].Torres[0].Vendido) {
            prueba.push("Vendido");
          }
          let selector = <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />;
          return (
            <div onClick={this.changeScreen}>


              <div className="selectorDispo">{selector}</div>

              <div>
                <motion.div
                  exit={{ x: 1920 }}
                  animate={{ x: 0 }}
                  initial={{ x: -1920 }}
                  // Dependiendo de donde venga la historia del navegador entra por la izquierda o derecha
                  /* initial={{
                     x:
                       this.props.history.location.state.returnTo === "/modelos"
                         ? 1920
                         : -1920,
                   }}*/
                  transition={{ duration: 0.3 }}
                >
                  {/* Aplica el font a todos los elementos */}
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        `
  * { 
  font-family: ` +
                        this.state.data.desarrollos[0].identidad.Font.name.split(
                          "."
                        )[0] +
                        ` !important }
`,
                    }}
                  />

                  <div className="" id="disponibilidad">
                    {console.log(this.props.match.params)}
                    <Breadcrumb
                      lk={"/" + this.props.match.params.desarrollo + "/disponibilidad"}
                      tabs={[
                        // eslint-disable-next-line
                        this.state.data.desarrollos[0].Filtrado == "Ninguno"
                          ? 'inicio'
                          : 'inicio',
                        "Disponibilidad",
                      ]}
                      links={[
                        "/" + this.props.match.params.desarrollo,

                        "/" + this.props.match.params.desarrollo + "/disponibilidad",
                      ]}
                      onClick={() => {
                        localStorage.setItem("regreso", "true")
                        localStorage.setItem("mapa", "true")
                        this.setState({
                          showComponent: false,
                        });
                      }}

                    />

                    {data.DistribucionVertical ? (<LeftTitleVertical title={data.Izquierda} />) : (<LeftTitle title={data.Izquierda} />)}
                    {data.DistribucionVertical ? (<RightTitleVertical title={data.Derecha} />) : (<RightTitle title={data.Derecha} />)}

                    <ul>
                      {prueba.map((tipo) => {
                        if (this.props.match.params.desarrollo == "1" && tipo == "No Disponible" && data.Nombre == "SANTA HELENA") {
                          var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase() + "-1";
                        } else {
                          var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase();
                        }
                        return <li key={tipo}>
                          <div
                            className={
                              classNameString
                            }
                          ></div>
                          {tipo}
                        </li>
                      })}
                    </ul>
                    {/* Genera el GIS */}
                    {
                      this.state.torre.MapaTerreno ? <GeoJsonMapBack
                        data={this.state.geo}
                        disponibilidadData={this.state.disponibilidad.data}
                        zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl}
                        id="map"
                        datos={this.state.data.desarrollos[0]}
                        idDesarrollo={this.props.match.params.desarrollo}
                        atributionMap = {true}
                      />
                      : <GeoJsonMap
                      data={this.state.geo}
                      disponibilidadData={this.state.disponibilidad.data}
                      zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl}
                      id="map"
                      datos={this.state.data.desarrollos[0]}
                      idDesarrollo={this.props.match.params.desarrollo}
                    />
                    }
                    
                    { }
                  </div>
                </motion.div>
              </div>
            </div>
          );
        } catch (error) {
          console.log(this.state.data);
          console.error(error);
          if (this.state.data === undefined) {
            return null;
          }
          return <Fallback />;
        }
      } else if (this.state.data2.Torres.length <= 1) {
        localStorage.removeItem("prototipoID");
        localStorage.removeItem("torreName");
        //localStorage.removeItem("torreID");
        localStorage.removeItem("prototipoName");
        localStorage.removeItem("id")
        let id = datos.Torres[0].id;
        if (this.state.data == undefined) {

          this.consultaGis(id)
          return <Loading />;
        }
        localStorage.setItem("id", datos.Torres[0].id);
        localStorage.setItem("torreID", datos.Torres[0].id);
        localStorage.setItem("disponibilidad", true);
        localStorage.setItem("flag", true)



        try {
          if (this.state.data.desarrollos[0].Torres[0] === undefined) {
            console.log('nda');
          }
          var data = this.state.data.desarrollos[0].Torres[0];

          const prueba = [];
          if (this.state.data.desarrollos[0].Torres[0].NoDisponible) {
            prueba.push("No Disponible");
          }
          if (this.state.data.desarrollos[0].Torres[0].Disponible) {
            prueba.push("Disponible");
          }
          if (this.state.data.desarrollos[0].Torres[0].NoFiltrado) {
            prueba.push("No Filtrado");
          }
          if (this.state.data.desarrollos[0].Torres[0].Separado) {
            prueba.push("Separado");
          }
          if (this.state.data.desarrollos[0].Torres[0].Vendido) {
            prueba.push("Vendido");
          }
          let selector = <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />;
          return (
            <div onClick={this.changeScreen}>
              <div className="selectorDispo">{selector}</div>

              <div>
                <motion.div
                  exit={{ x: 1920 }}
                  animate={{ x: 0 }}
                  initial={{
                    x: -1920
                  }}
                  // Dependiendo de donde venga la historia del navegador entra por la izquierda o derecha
                  /* initial={{
                     x:
                       this.props.history.location.state.returnTo === "/modelos"
                         ? 1920
                         : -1920,
                   }}*/
                  transition={{ duration: 0.3 }}
                >
                  {/* Aplica el font a todos los elementos */}
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        `
    * { 
    font-family: ` +
                        this.state.data.desarrollos[0].identidad.Font.name.split(
                          "."
                        )[0] +
                        ` !important }
  `,
                    }}
                  />

                  <div className="" id="disponibilidad">

                    <Breadcrumb
                      lk={"/" + this.props.match.params.desarrollo}
                      tabs={[
                        // eslint-disable-next-line
                        this.state.data.desarrollos[0].Filtrado == "Ninguno"
                          ? 'inicio'
                          : 'inicio',
                        "Disponibilidad",
                      ]}
                      links={[
                        "/" + this.props.match.params.desarrollo,

                        "/" + this.props.match.params.desarrollo + "/disponibilidad",
                      ]}
                      onClick={() => {
                        localStorage.setItem("regreso", "true")
                      }}

                    />
                    {data.DistribucionVertical ? (<LeftTitleVertical title={data.Izquierda} />) : (<LeftTitle title={data.Izquierda} />)}
                    {data.DistribucionVertical ? (<RightTitleVertical title={data.Derecha} />) : (<RightTitle title={data.Derecha} />)}

                    <ul>
                      {prueba.map((tipo) => {
                        if (this.props.match.params.desarrollo == "1" && tipo == "No Disponible" && data.Nombre == "SANTA HELENA") {
                          var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase() + "-1";
                        } else {
                          var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase();
                        }
                        return <li key={tipo}>
                          <div
                            className={
                              classNameString
                            }
                          ></div>
                          {tipo}
                        </li>
                      })}
                    </ul>
                    {/* Genera el GIS */}
                    <GeoJsonMap
                      data={this.state.geo}
                      disponibilidadData={this.state.disponibilidad.data}
                      zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl}
                      id="map"
                      datos={this.state.data.desarrollos[0]}
                      idDesarrollo={this.props.match.params.desarrollo}
                    />
                    { }
                  </div>
                </motion.div>
              </div>
            </div>
          );
        } catch (error) {
          console.log(this.state.data);
          console.error(error);
          if (this.state.data === undefined) {
            return null;
          }
          return <Fallback />;
        }
      }

    }
  }
}


